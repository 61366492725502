import React from 'react';

import { ButtonProps } from '../../../types';

import Button from '../Button';
import Styled from './index.styled';
import Browser from '../../../lib/browser';

type CTAProps = {
	heading?: string;
	ctaType?: 'primary' | 'secondary';
	buttons: Array<ButtonProps>;
	anchorId?: string;
};

const CTA = ({ heading, ctaType, buttons, anchorId }: CTAProps) => {
	const getCtaType = (): any => {
		switch (ctaType) {
			case 'primary':
				return Styled.CTAPrimary;
			case 'secondary':
				return Styled.CTASecondary;
			default:
				return Styled.CTAPrimary;
		}
	};

	const StyledCTA = getCtaType();

	return (
		<StyledCTA as="section" id={anchorId || ''}>
			<Styled.CTA__Heading>
				<Styled.CTA__HeadingText as="h2">{heading}</Styled.CTA__HeadingText>
			</Styled.CTA__Heading>
			<Styled.CTA__Buttons>
				{buttons.map((item, index) => (
					<Styled.CTA__Button key={index}>
						<Button
							buttonLink={item.buttonLink}
							buttonText={item.buttonText}
							buttonCategory={item.buttonCategory}
							buttonAction={item.buttonAction}
							buttonLabel={item.buttonLabel}
							buttonType="primary"
							iconName={item.iconName}
							target={!item.isAnchorLink ? '_blank' : undefined}
							rel={!item.isAnchorLink ? 'noopener noreferrer' : undefined}
							callback={event => Browser.handleClick(event, item.buttonLink)}
							iconPosition={item.iconPosition || 'left'}
						/>
					</Styled.CTA__Button>
				))}
			</Styled.CTA__Buttons>
		</StyledCTA>
	);
};

export default CTA;
