import styled, { css } from 'styled-components';
import { bigTitleRegular } from '../Typography/index.styled';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';

const CTAPrimary = styled(Container)`
	margin-top: ${spacing[48]};
	margin-bottom: ${spacing[48]};

	${above(breakpoints[960], () => css`
		margin-top: ${spacing[64]};
		margin-bottom: ${spacing[64]};
	`)}

	${above(breakpoints[1440], () => css`
		margin-top: ${spacing[80]};
		margin-bottom: ${spacing[80]};
	`)}
`;

const CTASecondary = styled.section`
	position: relative;
	background-color: var(--primaryBackground);
	padding: ${spacing[48]} ${margins[16]};
	z-index: 0;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		width: 0;
		height: 0;
		border: 0 solid transparent;
		border-left-width: 16px;
		border-right-width: 16px;
		border-top: 16px solid var(--secondaryBackground);
		transform: translateX(-50%);
	}

	${above(breakpoints[600], () => css`
		padding-right: ${margins[24]};
		padding-left: ${margins[24]};

		&::before {
			border-left-width: 24px;
			border-right-width: 24px;
			border-top: 24px solid var(--secondaryBackground);
		}
	`)}

	${above(breakpoints[960], () => css`
		padding: ${spacing[64]} ${margins[48]};
	`)}

	${above(breakpoints[1440], () => css`
		padding: ${spacing[80]} ${margins[80]};
	`)}

	${above(breakpoints[1920], () => css`
		padding-right: ${margins[120]};
		padding-left: ${margins[120]};
	`)}
`;

const CTA__Heading = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CTA__HeadingText = styled(bigTitleRegular)`
	color: var(--primaryForeground);
	text-align: center;
	margin-bottom: ${gap[20]};
	max-width: 80ch;

	${above(breakpoints[600], () => css`
		margin-bottom: ${gap[24]};
		max-width: 60%;
	`)}

	${above(breakpoints[960], () => css`
		margin-bottom: ${gap[40]};
		max-width: 80%;
	`)}
`;

const CTA__Buttons = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;

	${above(breakpoints[600], () => css`
		align-items: stretch;
		flex-direction: row;
		justify-content: center;
	`)}
`;

const CTA__Button = styled.div`
	&:not(:last-child){
		margin-bottom: ${gap[16]};
	}

	${above(breakpoints[600], () => css`
		margin: 0 ${gap[16]};
	`)}
`;

export default {
	CTAPrimary,
	CTASecondary,
	CTA__Heading,
	CTA__HeadingText,
	CTA__Buttons,
	CTA__Button
};
